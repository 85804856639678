<template>
  <div>
    <b-modal id="cancellation-reasons"
             centered
             title="Cancellation of Services"
             size="lg"
             no-close-on-esc
             no-close-on-backdrop
    >
      <template #modal-footer class="action-buttons justify-content-end">
        <b-button class="grey-button-outlined mr-2"
                  variant="outline-secondary"
                  aria-label="Back to service button"
                  @click="onClose"
        >
          <fa-icon icon="chevron-left" class="fa-lg mr-2" />
          <span>Back to Services</span>
        </b-button>
        <b-button
          class="custom-red-button mr-3"
          aria-label="Confirm cancellation button"
          @click="cancellationFeedback"
        >
          Confirm Cancellation
        </b-button>
      </template>

      <template #default>
        <b-alert
          v-if="selectedBizIdServices?.length"
          class="d-flex align-items-center m-3"
          variant="danger"
          show
        >
          <b-icon
            icon="exclamation-triangle-fill"
            variant="warning"
            class="mr-2"
          />
          <div v-if="!selectedHostingTypes && sslSelected">
            Any website or email service associated with a cancelled SSL service will <strong>lose their secure encryption</strong>.
          </div>
          <div v-else>
            <strong>All {{ selectedHostingTypes }} data will be deleted</strong> from our database after 45 days.
            Reactivate service from Websites/Email tab at any time.
          </div>
        </b-alert>
        <div class="services-container">
          <div v-for="(company, index) in companies" :key="index" class="service-card">
            <div class="card-header">{{ company.name }}</div>
            <div v-for="(service, service_index) in company.services" :key="service_index" class="service-row">
              <span>
                <p class="service-name">{{ service?.product?.name }}</p>
                <p class="jurisdiction">{{ service?.jurisdiction?.formatted }}</p>
              </span>
              <span>
                <button class="remove-button" @click="removeService(service.id)">
                  <fa-icon icon="minus-circle" class="fa-lg mr-2" />
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="cancellation-info-container">
          <div class="cancellation-info-text">
            <h5>
              Heads up! Cancelling Services will result in...
            </h5>
            <ul>
              <li v-for="(item, index) in cancellationInfoText" :key="index">
                {{ item }}
              </li>
            </ul>
            <span>Our services are hassle free and make your life easier. <strong>Do you wish to continue?</strong></span>
          </div>
        </div>
        <div class="reason-container">
          <b-form @submit="cancellationFeedback">
            <b-form-group>
              <label class="label-sm">Reason for Cancellation</label>
              <b-form-select v-model="feedBack" class="select-box">
                <option v-for="option in cancellationOptions" :key="option.cancellation_reason" :value="option.id">
                  {{ option.cancellation_reason }}
                </option>
              </b-form-select>
              <div v-if="isOtherOption">
                <label class="label-sm mt-3">Please explain in 100 characters or less</label>
                <b-form-textarea
                  id="textarea"
                  v-model="feedBackIfOther"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  required
                  maxlength="100"
                />
              </div>
              <div v-if="servicesIncludesVirtualOffice">
                <label class="label-sm mt-3 text-danger">Warning: Cancelling a Virtual Office service will cancel associated Virtual Phone services.</label>
              </div>
            </b-form-group>
          </b-form>
        </div>
      </template>
    </b-modal>
    <b-modal id="cancel-services" hide-footer
             title="Service Cancellation Agreement"
             :size="size"
    >
      <div class="agreement-modal">
        <banner
          v-if="cancelError"
          class="mb-2"
          message="There was an issue processing your cancellation, please try again or contact customer service"
          :icon="['fas', 'times-circle']"
          :icon-color="'#CB2328'"
          :banner-background-color="'#FFEBEE'"
          :banner-border-color="'#CB2328'"
          :status="'Failed'"
        />

        <div v-if="outstandingBalance > 0">
          <p>
            You are cancelling your subscription. We charge by the day and your pro-rated amount is:
          </p>

          <p>Balance Due: {{ outstandingBalance }}</p>
          <select-payable :invoice-view="false" @payableInfo="payableInfo" />
        </div>
        <div v-if="activeFilings.length > 0 || loadingActiveFilings">
          <p>
            <strong>Active Filings</strong>
            <br>
            <span v-if="!loadingActiveFilings">
              The following filings are affected by cancelling these services. Select ones you would like to cancel.
            </span>
          </p>
        <ct-table
          ref="activeFilingsTable"
          :items="activeFilings"
          :fields="filingFields"
          :loaded="filingsLoaded"
          :selectable-function="selectableFunction"
          :selectable="true"
          select-mode="multi"
          :is-paginated="false"
          @selection-changed="selectionEvent"
        />
        </div>
        <service-cancellation-text :services="selectedServices" />
        <strong>Representative</strong>
        <p class="m-b-lg">
          <i>
            By typing your name, you are agreeing to the end of service agreement. You are agreeing this
            is the equivalent of a traditional signature. This will cancel the selected services with
            us.
          </i>
        </p>
        <b-form-group>
          <label class="label-lg">* Enter your name to agree</label>
          <b-form-input v-model="signature" type="text" class="form-control" required />
        </b-form-group>
        <div class="d-flex justify-content-center">
          <b-button
            class="mt-4 submit-btn-width"
            variant="danger"
            :disabled="submitBtnDisabled || loading || loadingActiveFilings"
            @click="cancelServices"
          >
            <b-spinner v-if="loading" small />
            <div v-else>
              <div v-if="selection.length > 0">
                I agree, cancel my services and filings
              </div>
              <div v-else>
                I agree, cancel my services
              </div>
            </div>
          </b-button>
          <b-button
            v-if="outstandingBalance > 0"
            class="mt-4 submit-btn-width ml-3"
            variant="dark"
            :disabled="withoutPayingBtnDisabled || loading || loadingActiveFilings"
            @click="cancelServicesWithoutPaying"
          >
            <b-spinner v-if="loading" small />
            <div v-else>
              I agree, cancel without paying
            </div>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import SelectPayable from '@/components/SelectPayable.vue'
import { mapActions, mapGetters } from 'vuex'
import CtTable from '@/components/shared/CtTable'
import ServiceCancellationText from '@/components/cancellation/ServiceCancellationText'
import http from '../../http.js'
import { makeToastMixin } from '@/mixins/makeToastMixin.js'
import Banner from '@/components/shared/Banner.vue'

const REFUND_STATUSES = [
  'pending',
  'awaiting-client-input',
  'renewal-filed-by-client',
  'not-filed-in-state',
  'new',
  'waiting-on-signature',
  'waiting-on-cc-auth',
  'waiting-on-information',
  'held',
  'revoked-in-state',
]

export default {
    name: 'CancellationModal',
    components: { Banner, SelectPayable, ServiceCancellationText, CtTable },
    mixins: [makeToastMixin],
    props: {
      bus: null,
    },
    data() {
      return {
        signature: null,
        feedBack: false,
        feedBackIfOther: null,
        payable: null,
        services: null,
        companies: {},
        selectedServiceTypes: [],
        outstandingBalance: null,
        loading: false,
        loadingActiveFilings: false,
        raServices: [],
        compServices: [],
        cancelError: null,
        refundableComplianceFilings: {},
        refundParams: [],
        refundTotal: 0,
        selection: [],
        selectedServices: [],
        size: 'xl',
        filingFields: [
          { key: 'company.name', label: 'Company', sortable: true, sortAs: 'company.name' },
          { key: 'name', label: 'Filing Type', sortable: true },
          { key: 'registration.jurisdiction.state_province_region', label: 'Jurisdiction', sortable: true, sortAs: 'registration.jurisdiction.state_province_region' },
          { key: 'status', label: 'Status', sortable: true },
        ],
        cancellationInfoText: [
          'Losing access to our expert support team.',
          'Having to stay informed of all the ins and outs of your state.',
          'Wasting valuable time that could be spent running your business.',
        ],
      }
    },
    computed: {
      ...mapGetters('account', ['actualAccountID']),
      ...mapGetters('orderItems', ['activeFilings', 'filingsLoaded']),
      ...mapGetters('cancellationReasons', ['allOptions', 'allOptionsGeneric', 'allOptionsForServiceType', 'otherOptions']),
      cancellationOptions() {
        if (this.selectedServiceTypes.length === 1 && this.selectedServiceTypes[0] === 'virtual-phone') {
          return this.allOptionsForServiceType(this.selectedServiceTypes[0])
        } else if(this.selectedServiceTypes.length > 1 && this.selectedServiceTypes.includes('virtual-phone')) {
          return this.allOptions
        } else {
          return this.allOptionsGeneric
        }
      },
      submitBtnDisabled() {
        return this.invalidSignature() || this.noPayableForPayment()
      },
      withoutPayingBtnDisabled() {
        return this.invalidSignature()
      },
      isOtherOption() {
        // For some reason the feedBack variable is used as a boolean and an otherOption id
        if (this.feedBack) {
          return this.otherOptions.filter(o => o.id === this.feedBack).length > 0
        } else {
          return false
        }
      },
      servicesIncludesVirtualOffice() {
        return this.services?.some(service => service.product?.name?.includes('Virtual Office'))
      },
      selectedBizIdServices() {
        const bizIdTypes = ["business-website-hosting", "business-email", "domain-ssl"]
        return this.services?.filter(service => bizIdTypes.includes(service.type))
      },
      selectedBizIdServiceTypes() {
        return this.selectedBizIdServices.map(service => service.type)
      },
      sslSelected() {
        return this.selectedBizIdServiceTypes.includes("domain-ssl")
      },
      webSelected() {
        return this.selectedBizIdServiceTypes.includes("business-website-hosting")
      },
      emailSelected() {
        return this.selectedBizIdServiceTypes.includes("business-email")
      },
      selectedHostingTypes() {
        if (this.webSelected && this.emailSelected) {
          return "website and email"
        } else if (this.webSelected) {
          return "website"
        } else if (this.emailSelected) {
          return "email"
        } else {
          return null
        }
      },
    },
    mounted() {
      this.getAllOptions()
      this.$root.$on('bv::modal::hidden', () => {
        this.$emit('modal-change', false)
      })
      this.bus.$on('showCancelServices', (services) => {
        this.services = services
        this.groupServicesByCompany()
        this.openCancellationReasons()
      })
    },
    methods: {
      ...mapActions('orderItems', ['loadJurisdictionActiveFilings', 'loadComplianceActiveFilings', 'cancelOrderItems']),
      ...mapActions('invoices', ['fetchInvoiceFromOrderItem', 'updateRefundTotal']),
      ...mapActions('cancellationReasons', ['getAllOptions']),
      ...mapActions('services', ['cancelAndRefundServicesAndFilings']),
      openCancellationReasons() {
        this.feedBack = false
        this.feedBackIfOther = null
        this.selectedServiceTypes = [...new Set(this.services.map(s => s.type))]
        this.$bvModal.show('cancellation-reasons')
      },
      groupServicesByCompany() {
        this.companies = this.services.reduce((acc, service) => {
          const companyId = service.company.id

          if (!acc[companyId]) {
            acc[companyId] = { name: service.company.name, services: [] }
          }

          acc[companyId].services.push(service)
          return acc
        }, {})
      },
      async cancellationFeedback(event) {
        this.cancelError = null
        event.preventDefault()
        this.$bvModal.hide('cancellation-reasons')
       await this.open()
      },
      async open() {
        this.selectedServices = this.services
        this.$bvModal.show('cancel-services')

        // Check all the selected services and pull out the ones with potential filings
        // that need to be canceled. This will be any filings for a company within the state the RA
        // service is being cancelled in, along with any filings generated within 90 days for compliance
        // services
        this.raServices = this.selectedServices.filter(s => s.type === 'registered-agent').map(s => s = { companyId: s.company_id, jurisdictionId: s.jurisdiction.id } )
        this.compServices = this.selectedServices.filter(s => s.type === 'compliance').map(s => s = s.id)
        this.loadActiveFilings()
        this.checkOutstandingBalance()
        // Clear out payable and signature in the case the user closes and the re-opens the modal
        this.payable = null
        this.signature = null
      },
      selectedServiceIds(services) {
        return services.map(a => a.id)
      },
      payableInfo(payableId) {
        this.payable = payableId
      },
      selectableFunction(filing) {
        return filing.status !== 'cancel-requested'
      },
      selectionEvent(selection) {
        this.selection = selection
      },
      invalidSignature() {
        return !this.signature || this.signature === ''
      },
      noPayableForPayment() {
        return this.payable === null && this.outstandingBalance > 0
      },
      async checkOutstandingBalance() {
        this.loading = true
        const result = await http.post('client/services/outstanding_balance_info', {
          services: { service_ids: this.selectedServiceIds(this.selectedServices) },
        })
        this.outstandingBalance = result.data.result.balance.toFixed(2)
        this.loading = false
      },
      async cancelServices() {
        if (this.invalidSignature()){
            this.warningToast('warning','You must provide a valid signature before proceeding.')
          return
        }
        this.loading = true
        this.cancelError = null
        let cancelResponse = ''
        this.refundTotal = 0
        const uniqueCompanies = [...new Set(this.selectedServices.map(item => item.company_id))]
        const outstandingBalanceExists = this.outstandingBalance > 0

        for (const company_id of uniqueCompanies) {
          this.refundParams = []
          const companyServices = this.selectedServices.filter(x => x.company_id === company_id)

          const companyFilings = this.selection.filter(f=> f.company.id === company_id).map(f => f.id)
          const refundableFilings = companyFilings.filter(filing => this.refundableComplianceFilings[filing])
          if (outstandingBalanceExists) {
            cancelResponse = await this.payOutstandingBalanceAndCancel(
              companyServices,
              this.selection.filter(f=> f.company.id === company_id).map(f => f.id)
            ).catch(
              error => {
                const response = error.response
                response.type = 'Payment'
                return response
              }
            )
          } else if (refundableFilings.length > 0) {
            for (const filing of refundableFilings) {
              this.updateRefundParams(filing)
            }
            cancelResponse = await this.cancelAndRefund(
              companyServices,
              this.selection.filter(f=> f.company.id === company_id).map(f => f.id))
            if (cancelResponse?.data?.success) {
              this.refundTotal += cancelResponse?.data?.result?.response?.refund_responses
                .reduce((total, refund) => total + refund?.refund_response?.refund_response?.total, 0)
            }
          } else {
            cancelResponse = await this.noOutstandingBalanceCancel(
              companyServices,
              this.selection.filter(f=> f.company.id === company_id).map(f => f.id)
            ).catch(error => {
              const response = error.response
              response.type = 'Cancellation'
              return response
            })
          }
        }

        for (const selectedService of this.selectedServices) {
          if (selectedService.product.category === "virtual-phone") {
            await this.$store.dispatch('virtualPhones/loadVirtualPhones', null, { root: true })
            break
          }
        }

        this.loading = false
        if (cancelResponse?.data?.success) {
          this.updateRefundTotal(this.refundTotal)
          this.$emit('cancel-complete')
          this.$bvModal.hide('cancel-services')
        }
        else {
          this.cancelError = cancelResponse
        }
      },
      async cancelServicesWithoutPaying() {
        if (this.invalidSignature()) {
          this.warningToast('warning','You must provide a valid signature before proceeding.')
          return
        }
        this.loading = true
        this.cancelError = null
        let cancelResponse = ''
        const uniqueCompanies = [...new Set(this.selectedServices.map(item => item.company_id))]

        for (const company_id of uniqueCompanies) {
          const companyServices = this.selectedServices.filter(x => x.company_id === company_id)
          cancelResponse = await this.noOutstandingBalanceCancel(companyServices, this.selection.map(f => f.id)).catch(error => {
            let response = error.response
            response.type = 'Cancellation'
            return response
          })
        }
        this.loading = false
        if (cancelResponse?.data?.success) {
          this.$emit('cancel-complete')
          this.$bvModal.hide('cancel-services')
        }
        else {
          this.cancelError = cancelResponse
        }
      },
      async loadActiveFilings() {
        this.loadingActiveFilings = true
        await this.loadJurisdictionActiveFilings(this.raServices)
        await this.loadComplianceActiveFilings(this.compServices)
        for (const filing of this.activeFilings) {
          if (REFUND_STATUSES.includes(filing.status) && filing.product.name === 'Annual Report Compliance') {
            const invoice = await this.fetchInvoiceFromOrderItem(filing.id)
            if (invoice["status"] === 'paid' && invoice["last4"]) {
              this.refundableComplianceFilings[filing.id] = invoice
            }
          }
        }
        this.$nextTick(() => {
          if (this.$refs.activeFilingsTable) {
            this.$refs.activeFilingsTable.selectAllRows()
          }
        })
        this.loadingActiveFilings = false
      },
      async payOutstandingBalanceAndCancel(services, orderItemIds) {
        return await http.post('client/cancellations/pay_outstanding_balance_and_cancel_services_and_filings', {
          cancel: {
            token: this.payable,
            service_ids: this.selectedServiceIds(services),
            order_item_ids: orderItemIds,
            signature: this.signature,
            note: null,
            cancellation_reason_detail: this.feedBackIfOther,
            selected_cancellation_reason_id: this.feedBack,
          },
        })
      },
      async noOutstandingBalanceCancel(services, orderItemIds) {
        return await http.post('client/cancellations/cancel_services_and_filings', {
          cancel: {
            service_ids: this.selectedServiceIds(services),
            order_item_ids: orderItemIds,
            signature: this.signature,
            note: null,
            cancellation_reason_detail: this.feedBackIfOther,
            selected_cancellation_reason_id: this.feedBack,
          },
        })
      },
      async cancelAndRefund(services, order_items) {
        const nonRefundableOrderItems = order_items.filter(id =>
          !this.refundableComplianceFilings.hasOwnProperty(id)
        )
        const params = {
          cancel: {
            service_ids: this.selectedServiceIds(services),
              order_item_ids: nonRefundableOrderItems,
              signature: this.signature,
              note: null,
              cancellation_reason_detail: this.feedBackIfOther,
              selected_cancellation_reason_id: this.feedBack,
          },
          refunds: this.refundParams,
        }
        return await this.cancelAndRefundServicesAndFilings(params)
      },
      onClose() {
        this.$bvModal.hide('cancellation-reasons')
      },
      removeService(id) {
        this.services = this.services.filter(service => service.id !== id)
        if (this.services.length < 1) {
          this.$bvModal.hide('cancellation-reasons')
        }
        this.groupServicesByCompany()
      },
      updateRefundParams(filing) {
        const reasonSelected = this.cancellationOptions.find(option => option.id === this.feedBack)
        const reason = reasonSelected["cancellation_reason"] !== 'Other'
          ? `(Client Requested) ${reasonSelected["cancellation_reason"]}`
          : `(Client Requested) ${this.feedBackIfOther}`

        this.refundParams.push({
          order_item_ids: [filing],
          account_id: this.actualAccountID,
          invoice_id: this.refundableComplianceFilings[filing]["id"],
          refund_reason: reason,
          signature: this.signature,
          note: null,
          cancellation_reason_detail: this.feedBackIfOther,
          selected_cancellation_reason_id: this.feedBack,
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .alert-danger {
    color: black;
    background-color: #ffa5001c;
    border-color: orange;
    padding: 0.5rem 0.75rem;
  }
  ::v-deep .modal-header {
    background-color: white !important;
    color: black !important;
  }

  ::v-deep .modal-header .close {
    color: black !important;
    opacity: 1 !important;
  }

  ::v-deep .modal-body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .agreement-modal {
    padding: 1rem;
  }

  .services-container {
    display: flex;
    max-height: 55vh;
    flex-direction: column;
    padding: 1rem 1rem 1rem 1rem;
    margin: auto;
    overflow-y: auto;

    .service-card {
      width: 100%;
      margin-bottom: 1rem;
      border: 1px solid #ddd;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      background-color: #fff;

      .card-header {
        padding: 1rem;
        font-weight: 600;
        font-size: 18px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #FFFFFF;
      }

      .service-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem;
        margin: 0;
        border-bottom: 1px solid #CECED2;

        .service-name {
          font-size: 14px;
          margin: 0;
          color: #4E4E52;
        }
        .jurisdiction {
          font-size: 12px;
          margin: 0;
          color: #88888C;
        }
        .remove-button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          color: #4E4E52;
          outline: none;
        }
      }

      .service-row:last-child {
        border-bottom: none;
      }
    }

    .service-card:last-child {
      margin-bottom: 0;
    }
  }

  .cancellation-info-container {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #F7F7FB;

    .cancellation-info-text {
      padding: 1rem;
    }
  }

  .reason-container {
    padding: 1rem 1rem 1rem 1rem;
    margin: auto;
    font-weight: 400;

    .select-box {
      min-height: unset !important;
      height: 40px;
      border-radius: 8px;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .grey-button-outlined {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #FFFFFF !important;
    border-color: #CECED2 !important;
    border-radius: 5px;
    color: #4E4E52;
    padding: .5rem 1rem .5rem 1rem
  }
  .grey-button-outlined:hover {
    background-color: #CDCDCD !important;
  }

  .custom-red-button {
    background-color: #F93F36 !important;
    border-radius: 5px;
    color: white !important;
  }

  .custom-red-button:hover {
    background-color: #D32F2F !important;
  }

  .cancel-button:hover {
    cursor: pointer;
  }
</style>
